import React from "react"
import Logo from "../components/Logo"

function Home() {
  return (
    <>
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html: `
      body {
        margin: 0;
      }
    `,
        }}
      />
      <div
        style={{
          backgroundColor: "black",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo />
      </div>
    </>
  )
}

export default Home
